.page-grid {
  grid-area: page;
  display: grid;
  grid-template-rows: repeat(3, minmax(min-content, max-content));
  grid-row-gap: 5rem;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "blank1 headline blank2"
    "blank1 subTitle blank2"
    "blank1 sections blank2";

  & > :nth-child(1) {
    display: grid;
    grid-area: headline;
    align-self: center;
    justify-self: center;
    padding-top: 2rem;
    padding-bottom: 5rem;
  }

  & > :nth-child(2) {
    display: grid;
    grid-area: subTitle;
    align-self: center;
    justify-self: center;
    padding-bottom: 5rem;
  }

  & > :nth-child(3) {
    grid-area: sections;
    align-self: center;
    justify-self: center;
    display: grid;
    grid-row-gap: 2rem;
  }
}

.cta {
  display: grid;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding-top: 2rem;
  text-align: center;

  & > :nth-child(2) {
    padding-top: 2rem;
    font-size: var(--font-size-medium) !important;
    text-align: center;
  }

  & > :nth-child(3) {
    padding-top: 2rem;
  }
}

.sub-title {
  & > :nth-child(1) {
    color: var(--selected-font-grey-color);
  }
}

.section {
  display: grid;
  grid-gap: 0.5rem 3rem;
  text-align: justify;
  height: 100%;
  min-height: 100%;
  padding-top: 3rem;

  & > :nth-child(1) {
    align-self: end;
  }

  & > :nth-child(2) {
    padding-top: 1rem;
    align-self: end;
  }

  & > :nth-child(3) {
    align-self: start;
  }

  @media (--viewport-l) {
    min-height: 80vh;
    grid-gap: 3rem;

    & > :nth-child(1) {
      grid-row: 1;
      grid-column: 1 / 3;
      align-self: end;
    }

    & > :nth-child(2) {
      padding-top: 0;
      align-self: start;
      grid-row: 2;
      grid-column: 1;
    }

    & > :nth-child(3) {
      align-self: start;
      grid-row: 2;
      grid-column: 2;
    }
  }
}
