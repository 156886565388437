.section-title-wrapper {
  display: grid;
}

.styled-line {
  justify-self: center;
  align-self: end;
  height: 5rem;
  border-radius: 100% 100% 0 0;
  width: 0.1rem;
  background-image: linear-gradient(to top, var(--start-color), var(--mid-color), var(--end-color));
}

.styled-index {
  display: grid;
  align-self: start;
  text-align: center;
  justify-self: center;
  border-radius: 100%;
  height: calc(2.5rem + 0.7vw);
  width: calc(2.5rem + 0.7vw);
  font-size: calc(1rem + 0.7vw);
  background-image: linear-gradient(90deg, var(--start-color), var(--mid-color), var(--end-color));

  & > :nth-child(1) {
    color: var(--selected-background-color);
    line-height: 1.8 !important;
  }
}

.styled-main-title {
  padding: 1rem;
  justify-self: center;

  & > :nth-child(1) {
    display: inline-block;
    background-image:
      linear-gradient(
        90deg,
        var(--start-color),
        var(--mid-color),
        var(--end-color)
      );
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    text-align: center;
    color: var(--light-font-color);
    font-size: calc(var(--font-size-large) + 1rem + 1vw);
  }
}

.styled-below-title {
  font-weight: bold;
  text-align: center;
  font-size: var(--font-size-large);
}
