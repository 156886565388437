.logo-wrapper {
  display: grid;
  background: transparent;
  border-radius: 3px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  border-radius: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 2;
}

@keyframes pulse {
  to {
    opacity: 0;
    transform: scale(1);
  }
}

.logo-wrapper::before,
.logo-wrapper::after {
  animation: pulse 2s linear infinite;
  border: var(--selected-action-color) solid 6px;
  border-radius: 100%;
  box-sizing: border-box;
  content: " ";
  height: 100%;
  left: 1px;
  opacity: 0.9;
  position: absolute;
  top: 1px;
  transform: scale(0.712);
  width: 100%;
  z-index: 1;
}

.logo-wrapper::after {
  animation-delay: 1s;
}

.logo-wrapper:hover::after {
  animation-delay: 0.5s;
}
