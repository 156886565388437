.hero-title-wrapper {
  display: grid;

  & > :nth-child(n) {
    text-align: center;
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    line-height: 1.3;
    font-size: calc(var(--font-size-large) + 1.5rem + 1.3vw);
  }
}

.version-color {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;

  & > :nth-child(1) {
    z-index: -2;
    line-height: 1.3;
    display: inline-block;
    background-image:
      linear-gradient(
        90deg,
        var(--start-color),
        var(--mid-color),
        var(--end-color)
      );
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    text-align: center;
    font-size: calc(var(--font-size-large) + 1.5rem + 1.3vw);
  }
}

.version-black {
  --stagger-delay: 2000ms;

  grid-column: 1;
  grid-row: 1;
  animation-delay: calc(var(--index) * var(--stagger-delay));
  animation-duration: 8s;
  animation-fill-mode: both;
  color: var(--selected-font-color);
  animation-name: fade-version-black-out;
  animation-iteration-count: infinite;

  & > :nth-child(1) {
    z-index: -1;
    line-height: 1.3;
    display: inline-block;
    font-size: calc(var(--font-size-large) + 1.5rem + 1.3vw);
  }
}

@keyframes fade-version-black-out {
  0%,
  25% {
    opacity: 1;
  }

  40%,
  50% {
    opacity: 0;
  }

  70%,
  100% {
    opacity: 1;
  }
}
